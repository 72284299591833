import React from 'react';
import '../styles/Skills.css';

import JS from '../images/javascript.svg'; 
import Java from '../images/Java.png'; 
import Python from '../images/Python.png'; 
import C from '../images/C.png'; 
import html from '../images/html.png'; 
import css from '../images/css.png'; 
import andriod from '../images/andriod.webp';
import ecl from '../images/ecl.png';
import an from '../images/an.png';
import azure from '../images/azure.jfif';
import Fire from '../images/firebase.svg';
import mysql from '../images/mysql-logo.svg';
import dart from '../images/dart.svg';
import kt from '../images/kotlin.svg';
import react from '../images/React.png';
import express from '../images/express.svg';
import aws from '../images/aws.svg';
import pycham from '../images/pycham.png';
import vsc from '../images/vs.png';
import opencv from '../images/opencv.png';

import MariaDB from '../images/MariaDB_Logo.d8a208f0a889a8f0f0551b8391a065ea79c54f3a.png';
import Oracle from '../images/oracle-database-icon.png';
function Skills() {
  return (
    <section id="skills" className="skills-container">
      <div className='mainh1'>
      <h1>Skills</h1>
      <br/>
      </div>

      <h2>Language</h2>
      <div className='he'>
      </div>
      <div className="content-container">
        <div className="skills-box-container">
          <div className="skills-info-box">
            <img src={Java} alt="Java" />
            <h3>Java</h3>
          </div>
          <div className="skills-info-box">
            <img src={Python} alt="Python" />
            <h3>Python</h3>
          </div>
          <div className="skills-info-box">
            <img src={JS} alt="JavaScript Icon" />
            <h3>JavaScript</h3>
          </div>
          <div className="skills-info-box">
            <img src={dart} alt="JavaScript Icon" />
            <h3>Dart</h3>
          </div>
          <div className="skills-info-box">
            <img src={kt} alt="JavaScript Icon" />
            <h3>Kotlin</h3>
          </div>
          <div className="skills-info-box">
            <img src={html} alt="HTML" />
            <h3>HTML</h3>
          </div>
          <div className="skills-info-box">
            <img src={css} alt="CSS" />
            <h3>CSS</h3>
          </div>

        </div>
      </div>

      <h2>Database</h2>
      <div className="content-container">
        <div className="skills-box-container">
        <div className="skills-info-box">
            <img src={mysql} alt="CSS" />
            <h3 >MySQL</h3>
          </div>
          
          <div className="skills-info-box">
            <img src={MariaDB} alt="CSS" />
            <h3>MariaDB</h3>
          </div>

          <div className="skills-info-box">
            <img src={Oracle} alt="CSS" />
            <h3>Oracle Database</h3>
          </div>

        </div>
      </div>

      <h2>Cloud Computing Platform</h2>
      <div className="content-container">
        <div className="skills-box-container">
        <div className="skills-info-box">
            <img src={aws} alt="HTML" />
            <h3>AWS</h3>
          </div>
          <div className="skills-info-box">
            <img src={azure} alt="HTML" />
            <h3>Azure</h3>
          </div>
          <div className="skills-info-box">
            <img src={Fire} alt="CSS" />
            <h3>FireBase</h3>
          </div>
        </div>
      </div>

      <h2>BackEnd</h2>
      <div className="content-container">
        <div className="skills-box-container">
        <div className="skills-info-box">
            <img src={express} alt="HTML" />
            <h3>Express.js</h3>
          </div>
        </div>
      </div>
      
      <h2>FrontEnd</h2>
      <div className="content-container">
        <div className="skills-box-container">
        <div className="skills-info-box">
            <img src={react} alt="HTML" />
            <h3>React.js</h3>
          </div>
        </div>
      </div>
      
      <h2>Computer Vision</h2>
      <div className="content-container">
        <div className="skills-box-container">
        <div className="skills-info-box">
            <img src={opencv} alt="HTML" />
            <h3>OpenCV</h3>
          </div>
        </div>
      </div>

      <h2>IDE</h2>
      <div className="content-container">
        <div className="skills-box-container">
          <div className="skills-info-box">
            <img src={andriod} alt="C Icon" />
            <h3>Android Studio</h3>
          </div>
          <div className="skills-info-box">
            <img src={ecl} alt="CSS" />
            <h3>Eclipse</h3>
          </div>
          <div className="skills-info-box">
            <img src={an} alt="C Icon" />
            <h3>Anaconda</h3>
          </div>
          <div className="skills-info-box">
            <img src={vsc} alt="C Icon" />
            <h3>Visual Studio Code</h3>
          </div>
          <div className="skills-info-box">
            <img src={pycham} alt="C Icon" />
            <h3>PyCharm</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
