// BeeBus.js
import React from 'react';
import '../styles/Azure.css'; // CSS 연결
import Navbar from './Navbar'; // 네비게이션 바 추가
import im from '../images/Azu.webp'; 
import Layout from './Footer';
import giticon from '../images/git.png'; 

function Azure() {
  return (
    <div>
           <Navbar /> {/* 네비게이션 바 렌더링 */}
      <div className="hero-container">
        <img 
          src={im} 
          alt="BeeBus Hero" 
          className="hero-image"
        />
      </div>
      <div className="Azure-container">
        <h1>Azure & Kakao 기능을 이용한 이미지생성</h1>
        <p>
          Azure 에서 제공하는 기능을이용해 음성으로 입력받아 Karlo를 통해 이미지를생성하는 프로젝트  
        </p>
        </div>
       <div className='top'>
        <h5>주요 기능</h5>
        <p>TTS (Text-to-Speech)</p>
        <p>STT (Speech-to-Text)</p>
        <p>Translator</p>
        <p>NLTK</p>
        </div>

        <div className='mid'>
        <h5>프로젝트 개요</h5>
        <p>
          이 프로젝트는 단순히 Azure 를 활용할 목적으로 개발한 프로젝트입니다 <br/>
          Azure 에서 제공하는 기능과을 이용하여 텍스트를 만들고 <br/>
          KALO 를 통해서 이미지를 생성합니다<br/>
        </p>
        </div>

        <div className='ad'>
        <h5>기능 설명</h5>
        <p>
          Azure 에서 사용한 기능은 다음과 같습니다 TTS, STT , Translator  먼저 사용자가 음성으로 생성하고싶은 이미지를 입력합니다<br/>
          그입력이 KoNLPy 를 통해 단어 단위로 분리되며 그 단어를 Azure(Translator) 를통해 번역됩니다 <br/>
          그 번역단어를 NLTK WordNet 에 의해 반의어를 도출해내고 네거티브 프롬프트로 사용되게됩니다 <br/>
          또한 단어로 쪼개졋던 입력은 다시 문장으로 합쳐 프롬프트로 사용됩니다 <br />
          프롬프트와 네거티브 프롬프트를 합쳐 Karlo 로 전송하게되며 Karlo에서 이미지를 생성하게됩니다 <br/>
        </p>
        </div>
        <div className='sp'>
        <h5>기술 스택</h5>
        <p>Python</p>
        <p>NLTK</p>
        <p>Kkma (KoNLPy)</p>
        <p>Azure(TTS,STT,Translator)</p>
        <p>Kakao API(Karlo)</p>
        <p>PIL</p>
        </div>
        
        <button className="bt"><a href='https://github.com/PSS-pr/Image-generation-using-Karlo-and-Azure'>
      <img src={giticon} alt="Button Icon" />
      <p>깃허브 이동</p></a>
    </button>
        <Layout/>

    </div>
  );
}
    
    export default Azure;
